import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import CtaPrimary from "../Buttons/primary-cta";
import Image from "../ImageQuerys/HeroImages";
import CtaSecondary from "../Buttons/secondary-cta";
import EventCtaStyles from "./EventCtaStyles";

const { Title } = Typography;

const TRACta = (props) => {
  const { sectionData } = props;
  return (
    <section className="event-cta">
      <Row gutter={[32, 32]}>
        <Col lg={10} sm={24}>
          <h2 className="event-cta__head">
            Learn more at booth 944<span className="blue_dot">.</span>
          </h2>
        </Col>
        <Col lg={14} sm={24}>
          <p style={{ color: "#fff" }}>
            Find SpotOn at booth 944, right by the Big Bend Ideas stage, to
            learn more about the restaurant tech and personal support that can
            help keep you, your guests, and your employees happy.
          </p>
          <CtaPrimary target="/events/demo" ctaTitle="Learn more" />
        </Col>
      </Row>
      <div className="event-cta__second">
        <Row gutter={[64, 64]}>
          <Col lg={16} sm={24}>
            <Image imageName="tra_layout.png" />
          </Col>
          <Col lg={8} sm={24}>
            <h3>Henry B. Gonzales Convention Center San Antonio, Texas.</h3>
            <CtaSecondary
              target="https://www.google.com/maps/place/Henry+B.+Gonz%C3%A1lez+Convention+Center/@29.4208884,-98.4873117,17z/data=!3m2!4b1!5s0x865c58aa01766e23:0x664d9fb49b011f9a!4m5!3m4!1s0x865c58aa06483c27:0x9adbeeaa9ace85f0!8m2!3d29.4208884!4d-98.485123"
              ctaTitle="Google Maps"
              className="event-cta__maps"
              openInNewTab
            />
            <Image
              imageName="conference.png"
              className="event-cta__special-img"
            />
          </Col>
        </Row>
      </div>
      <EventCtaStyles />
    </section>
  );
};

TRACta.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
TRACta.defaultProps = {
  sectionData: "",
};

export default TRACta;
